<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered" v-if="loading">
        <div style="padding-left:45%">
          <p class="loader is-size-1"></p>
        </div>
      </div>
      <div class="container" v-if="project">
        <!-- If the project is open -->
        <template v-if="project.project_is_open">
          <!-- If a login method is available for this questionnaire -->
          <div v-if="!project.login_unavailable">
            <h1 class="title">
              {{ project.login_text_title }}
            </h1>
            
            <div style="margin-bottom: 1.5rem">
              <p v-html="project.login_text_content"></p>
            </div>
          
            <div v-if="project.login_check">
              <form>
                <div class="field">
                  
                  <label v-if="project.login_field_name" class="label has-text-white" for="login_name">
                    {{ $t('interface.'+project.login_field_name.toLowerCase()) | capitalize }}
                    <input type="text" v-model="loginName" class="input is-medium" id="login_name" :placeholder="$t('interface.'+project.login_field_name.toLowerCase()) | capitalize">
                  </label>
                  <label v-else class="label has-text-white" for="login_name">
                    Login name
                    <input type="text" v-model="loginName" class="input is-medium" id="login_name" placeholder="Login name">
                  </label>

                </div>
                <div class="field">
                  <label v-if="project.login_field_check" class="label has-text-white" for="login_check">
                    {{ $t('interface.'+project.login_field_check.toLowerCase()) | capitalize }}
                    <input type="text" v-model="loginCheck" class="input is-medium" id="login_check" :placeholder="$t('interface.'+project.login_field_check.toLowerCase()) | capitalize">
                  </label>
                  <label v-else class="label has-text-white" for="login_check">
                    Login check
                    <input type="text" v-model="loginCheck" class="input is-medium" id="login_check" placeholder="Login check">
                  </label>
                </div>
                <div class="control">
                  <button class="button is-link is-medium" @click.prevent="login">
                    <span class="icon">
                      <i class="fas fa-sign-in-alt"></i>
                    </span>
                    <span>{{ $t('interface.login') | capitalize }}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </template>
        
        <!--  -->
        <template v-else-if="project.login_unavailable">
          <h1 class="title">{{ $t('errors.project_no_login.title') | capitalize }}</h1>
        </template>

        <!-- If the project is close, show that to the user -->
        <template v-else>
          <h1 class="title">{{ $t('errors.project_closed.title') | capitalize }}</h1>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'login',
    data: function () {
      return {
        loading: true,
        loginName: '',
        loginCheck: '',
        project: this.getProject(),
      }
    },
    methods: {
      login () {
        const url = process.env.VUE_APP_API_URL + '/api/questionnaire/' + this.$route.params.slug + '/login'
        axios.post(url, {
          login_name: this.loginName.trim(),
          login_check: this.loginCheck.trim()
        })
        .then((response) => (
          this.$router.push('/' + response.data.language + '/questionnaire/' + response.data.questionnaire)
        ))
        .catch(() => {
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('errors.login.title'),
            text: this.$i18n.t('errors.login.message'),
            type: 'error'
          })
        })
      },
      getProject () {
        const url = process.env.VUE_APP_API_URL + '/api/project/' + this.$route.params.slug
        axios.get(url, { validateStatus: false })
        .then((response) => {
          this.loading = false
          if (response.status == 200 && response.data.login_unavailable) {
            this.project = {
              'login_unavailable': response.data.login_unavailable
            }
          } else if (response.status == 200) {
            this.project = {
              'name': response.data.project_name,
              'login_check': response.data.login_check,
              'login_field_name': response.data.login_field_name,
              'login_field_check': response.data.login_field_check,
              'login_text_title': response.data.login_text_title,
              'login_text_content': response.data.login_text_content,
              'project_email_link': response.data.email_link,
              'project_is_open': response.data.project_is_open,
            }
          } else {
            this.project = {
              'name': false,
              'login_field_name': false,
              'login_field_check': false,
              'login_text_title': this.$i18n.t('errors.noproject.title'),
              'login_text_content': this.$i18n.t('errors.noproject.message'),
              'project_email_link': false,
              'project_is_open': false,
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.project = {
            'name': false,
            'login_field_name': false,
            'login_field_check': false,
            'login_text_title': this.$i18n.t('errors.noproject.title'),
            'login_text_content': this.$i18n.t('errors.noproject.message'),
            'project_email_link': false,
            'project_is_open': false,
          }
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('errors.noproject.title'),
            text: this.$i18n.t('errors.noproject.message'),
            type: 'error'
          })
        })
      },
    },
    watch: {
      '$i18n.locale': function() {
        this.getProject()
      }
    },
  }
</script>
