<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered" v-if="loading">
        <div style="padding-left:45%">
          <p class="loader is-size-1"></p>
        </div>
      </div>
      <div class="container" v-if="project">
        <!-- If the project is open -->
        <template v-if="project.project_is_open">

          <!-- If a login method is available for this questionnaire -->
          <!-- @TODO make specific for mail option -->
          <div v-if="!project.login_unavailable">
            <h1 class="title">
              {{ $t('communicatie.emailform.title') | capitalize }}
            </h1>
            
            <div style="margin-bottom: 1.5rem">
              <p>
                 {{ $t('communicatie.emailform.message') | capitalize }}
              </p>
            </div>


            <!-- Show option to login with email link -->
            <div v-if="project.project_email_link">
              <div v-if="!loginEmailSend">
                <div class="field">
                  <label class="label has-text-white" for="login_email">
                    {{ $t('interface.emailadres') | capitalize }}
                  </label>
                  <input type="text" v-model="loginEmail" class="input is-medium" id="login_email" :placeholder="$t('interface.emailadres'.toLowerCase()) | capitalize">
                </div>
                <div class="control">
                  <button class="button is-link is-medium" @click.prevent="loginWithEmailLink">
                    <span class="icon">
                      <i class="fas fa-sign-in-alt"></i>
                    </span>
                    <span>{{ $t('interface.sendemail') | capitalize }} &raquo;</span>
                  </button>
                </div>
              </div>
              <div v-else>
                {{ $t('communicatie.emailsent.message') | capitalize }}
              </div>
            </div>
          </div>
        </template>
        
        <!--  -->
        <template v-else-if="project.login_unavailable">
          <h1 class="title">{{ $t('errors.project_no_login.title') | capitalize }}</h1>
        </template>

        <!-- If the project is close, show that to the user -->
        <template v-else>
          <h1 class="title">{{ $t('errors.project_closed.title') | capitalize }}</h1>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'sendmail',
    data: function () {
      return {
        loading: true,
        loginEmail: '',
        loginEmailSend: false,
        project: this.getProject(),
      }
    },
    methods: {
      loginWithEmailLink() {
        const url = process.env.VUE_APP_API_URL + '/api/questionnaire/' + this.$route.params.slug + '/email'
        axios.post(url, {
          login_email: this.loginEmail.trim(),
        })
        .then(() => {
          // Show notification that an email is send...
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('communicatie.emailsent.title'),
            text: this.$i18n.t('communicatie.emailsent.text'),
            type: 'success'
          })          
          this.loginEmailSend = true
        })
        .catch(() => {
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('errors.login.title'),
            text: this.$i18n.t('errors.login.message'),
            type: 'error'
          })
        })

        // this.loginEmailSend = true
      },
      getProject () {
        const url = process.env.VUE_APP_API_URL + '/api/project/' + this.$route.params.slug
        axios.get(url, { validateStatus: false })
        .then((response) => {
          this.loading = false
          if (response.status == 200 && response.data.login_unavailable) {
            this.project = {
              'login_unavailable': response.data.login_unavailable
            }
          } else if (response.status == 200) {
            this.project = {
              'name': response.data.project_name,
              'login_check': response.data.login_check,
              'login_field_name': response.data.login_field_name,
              'login_field_check': response.data.login_field_check,
              'login_text_title': response.data.login_text_title,
              'login_text_content': response.data.login_text_content,
              'project_email_link': response.data.email_link,
              'project_is_open': response.data.project_is_open,
            }
          } else {
            this.project = {
              'name': false,
              'login_field_name': false,
              'login_field_check': false,
              'login_text_title': this.$i18n.t('errors.noproject.title'),
              'login_text_content': this.$i18n.t('errors.noproject.message'),
              'project_email_link': false,
              'project_is_open': false,
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.project = {
            'name': false,
            'login_field_name': false,
            'login_field_check': false,
            'login_text_title': this.$i18n.t('errors.noproject.title'),
            'login_text_content': this.$i18n.t('errors.noproject.message'),
            'project_email_link': false,
            'project_is_open': false,
          }
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('errors.noproject.title'),
            text: this.$i18n.t('errors.noproject.message'),
            type: 'error'
          })
        })
      },
    },
    watch: {
      '$i18n.locale': function() {
        this.getProject()
      }
    },
  }
</script>
