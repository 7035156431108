import { Trans } from '@/plugins/Translation'

import LocalPage from '@/components/LocalPage.vue'
import Home from '@/components/Home.vue'
import Questionnaire from '@/components/questionnaire/Questionnaire.vue'
import Login from '@/components/Login.vue'
import SendMail from '@/components/SendMail.vue'
import PublicStart from '@/components/PublicStart.vue'
import InfoPage from '@/components/InfoPage.vue'


export default [
  {
    name: 'login-nolang',
    path: '/login/:slug',
    redirect (to) {
      return Trans.getUserSupportedLang() + to.path
    }
  },
  {
    name: 'sendmail-nolang',
    path: '/mail/:slug',
    redirect (to) {
      return Trans.getUserSupportedLang() + to.path
    }
  },
  {
    name: 'publicstart-nolang',
    path: '/start/:slug',
    redirect (to) {
      return Trans.getUserSupportedLang() + to.path
    }
  },
  {
    name: 'questionnaire-nolang',
    path: '/questionnaire/:questionnaire',
    redirect (to) {
      return Trans.getUserSupportedLang() + to.path
    }
  },
  {
    path: '/:lang',
    component: LocalPage,
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        name: 'Home',
        path: '',
        component: Home
      },
      {
        name: 'login',
        path: 'login/:slug',
        component: Login
      },
      {
        name: 'sendmail',
        path: 'mail/:slug',
        component: SendMail
      },
      {
        name: 'publicstart',
        path: 'start/:slug',
        component: PublicStart
      },
      {
        beforeEnter: Trans.routeMiddleware,
        name: 'questionnaire',
        path: 'questionnaire/:questionnaire',
        component: Questionnaire
      },
      {
        beforeEnter: Trans.routeMiddleware,
        name: 'infopages',
        path: 'info/:slug',
        component: InfoPage
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect () {
      return Trans.getUserSupportedLang()
    }
  }
]
