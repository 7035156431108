<template>
  <div id="app">
    <nav class="navbar is-primary is-fixed-top is-bold" role="navigation" aria-label="dropdown navigation">
      <div class="navbar-brand">
        <span class="navbar-item" :class="env">
          <div v-if="env == 'dev'" style="margin-right: 10px;">
            Development
          </div>
          <div v-else-if="env == 'uat'" style="color: black">
            UAT
          </div>
          <img src="/img/Nolost_Logo_rgb_diap.png" />

        </span>
        <a role="button" class="navbar-burger has-text-white" :class="{ 'is-active': menuActive }" aria-label="menu" aria-expanded="false" @click="toggleMenu()">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu is-primary" :class="{ 'is-active': menuActive }">
        <div class="navbar-end">
          <TheLanguageSwitcher/>
        </div>
      </div>
    </nav>
    <div>
      <section id="page">
        <router-view />
        <notifications group="notifications" classes="nolostcapital-notification" position="bottom right"/>
      </section>
    </div>
  </div>
</template>

<script>
  import TheLanguageSwitcher from './components/TheLanguageSwitcher'

  export default {
    name: 'app',
    components: {
      TheLanguageSwitcher
    },
    data: function () {
      return {
        menuActive: false,
        env: this.getEnv()
      }
    },
    methods: {
      toggleMenu: function(){
        this.menuActive = !this.menuActive
      },
      getEnv: function() {
        if (process.env.VUE_APP_API_URL.includes("localhost")) {
          return "dev"
        } else if (process.env.VUE_APP_API_URL.includes("uat")) {
          return "uat"
        } else {
          return "production"
        }
      }
    }
  }
</script>

<style>
  .nolostcapital-notification {
    padding: 10px;
    margin: 10px;
    color: #ffffff;
  }
  .nolostcapital-notification.info {
      background: #0092d0;
  }

  .nolostcapital-notification.warn {
      background: #ffb648;
  }

  .nolostcapital-notification.error {
      background: #E54D42;
  }

  .nolostcapital-notification.success {
      background: #68CD86;
  }
  .question span[data-tooltip] {
    border-bottom: none !important;
  }
</style>
